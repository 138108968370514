.login-row {
  height: 100vh;
}

.navbar-brand img {
  height: 45px;
}

.navbar-brand span {
  vertical-align: middle;
  font-size: 30px;
}

.logo-separator {
  height: 50px;
}

@media screen and (max-width: 991px) {
  .logo-separator {
    height: 0px;
    margin-top: 10px;
  }
}

.personal .profile-pic {
  max-width: 100%;
}

button i {
  vertical-align: middle;
}